<template>
	<div>
		<div class="mb20 pageTitle"><b>实时代付</b></div>
		<div v-loading="loading">
			<el-form :label-position="labelPosition" :label-width="labelWidth" class="form">
				<el-form-item label="商户号:">
					{{userInfo.business_no}}
				</el-form-item>
				<el-form-item label="可用余额:">
					{{userInfo.money | tofixed}}
				</el-form-item>
				<el-form-item label="账户类型:">
					个人账户
				</el-form-item>
				<el-form-item>
					<span class="cwarning">非常重要：所填信息必须真实有效，否则操作无法成功！</span>
				</el-form-item>
				<el-form-item label="收款户名:">
					<el-input style="width: 300px;"  class="mr20" placeholder="请输入收款户名" v-model="name" clearable></el-input>
				</el-form-item>
				<el-form-item label="收款账号:">
					<el-input style="width: 300px;"  class="mr20" placeholder="请输入收款账号" v-model="card" clearable></el-input>
				</el-form-item>
				<el-form-item label="开户行:">
					<el-input style="width: 300px;"  class="mr20" placeholder="请输入开户行" v-model="bank" clearable></el-input>
				</el-form-item>
				<el-form-item label="付款金额(元):">
					<el-input style="width: 300px;"  class="mr20" placeholder="请输入付款金额" v-model="money" clearable></el-input>
					<span class="cdanger">(温馨提示：为避免资金退回，请以商户最高额度提现。单笔最高{{maxmoney}}元)</span>
				</el-form-item>
				<el-form-item label="谷歌验证码:">
					<!-- <div class="flexX"> -->
						<el-input style="width: 300px;" class="mr20" placeholder="请输入谷歌验证码" v-model="code" clearable></el-input>
						<!-- <span class="cdanger inlineBlock whiteSpaceNowrap pointer" @click="validateGcode">绑定谷歌验证</span> -->
					<!-- </div> -->
				</el-form-item>
				<el-form-item>
					<el-button type="primary" class="mr20" @click="sure">提交申请</el-button>
					<span class="cinfo">( 注：实际到账时间请以银行时间为准 )</span>
				</el-form-item>

			</el-form>

		</div>
		
		
		
		<!-- <el-dialog center width="30%" title="绑定谷歌验证" :visible.sync="innerVisible" append-to-body>
			<div class="qrcode" v-loading="innerloading">
				<img :src="qrcode" alt="">
			</div>
		</el-dialog> -->
	</div>
</template>

<script>
	import local from '../../util/local.js'
	import {
		isEmpty,
		isPositiveNumber,
		isMoney,
		isAllNumber
	} from '@/util/validate'
	import {
		Message
	} from 'element-ui'
	export default {
		name: 'Home',
		components: {},
		data() {
			return {
				loading:false,
				labelPosition: 'right',
				userInfo: {},
				username: local.get('shusername'),
				labelWidth: '100px',
				innerVisible: false,
				innerloading: false,
				dialogloading: false,
				qrcode: '',
				name:'',
				card:'',
				bank:'',
				money:'',
				code:'',
				maxmoney:49999
			}
		},
		created() {
			this.getInfo()
		},
		filters: {
			tofixed: function(value) {
				if (value || value == 0) {
					return ((value * 1).toFixed(2) + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
				}
			}
		},
		methods: {
			//查询基本信息
			getInfo() {
				// this.dialogloading = true
				this.$api.userInfo({}).then(res => {
					this.dialogloading = false;
					if (res.status == 1) {
						this.userInfo = res.data
					}
				}).catch(error => {
					this.dialogloading = false
				})
			},
			validateGcode() {

				this.innerVisible = true;
				if (this.qrcode != '') {
					return
				}
				let data = {
					username: this.username,
				}
				// this.innerloading = true
				this.$api.validateGcode(data).then(res => {
					this.innerloading = false
					console.log(res)
					//1 成功   2未登录  其他失败
					if (res.status === 1) {
						let data = res.data;
						if (data != '') {
							this.qrcode = data
						} else {
							this.$message({
								type: 'info',
								message: '账号已完成谷歌验证，请输入验证码登录'
							})
						}
					} else {
						this.$message({
							type: 'info',
							message: res.msg
						});
					}
				}).catch(error => {
					this.innerloading = false
				})
			},
			sure() {
				// console.log('dddddddd')
				// return
				if(isEmpty(this.name,'请输入收款户名')){
					return
				}
				if(isEmpty(this.card,'请输入收款账号')){
					return
				}
				if(!isAllNumber(this.card,'收款账号只能是数字')){
					this.$message({type:'error',message:'收款账号只能是数字'})
					return
				}				
				if(isEmpty(this.bank,'请输入开户行')){
					return
				}				
				if(isEmpty(this.money,'请输入付款金额')){
					return
				}			
				if(!isMoney(this.money,'付款金额只能是数字，最多保留两位小数')){
					return
				}	
				if(Number(this.money) > Number(this.userInfo.money)){
					this.$message({type:'error',message:'余额不足'})
					return
				}
				if(Number(this.money) > Number(this.maxmoney)){
					this.$message({type:'error',message:`提现金额超过单笔最高${this.maxmoney}`})
					return
				}
				if(isEmpty(this.code,'请输入谷歌验证码')){
					return
				}
				if(!isAllNumber(this.code,'谷歌验证码只能是数字')){
					this.$message({type:'error',message:'谷歌验证码只能是数字'})
					return
				}		
				let data = {
					name:this.name,
					card:this.card,
					bank:this.bank,
					money:this.money,
					code:this.code,
				}
				this.loading = true;
				this.$api.createOrder(data).then(res=>{
					this.loading = false;
					if(res.status == 1){
						this.name = '';
						this.card = '';
						this.bank = '';
						this.money = '';
						this.code = '';
						this.$router.push({path:'/paymentRecord'})
						this.$message({type:'success',message:res.msg})
						this.getInfo()
					}else{
						this.$message({type:'error',message:res.msg})
					}
				}).catch(error=>{
					this.loading = false;
				})
			},
		}
	}
</script>



<style lang="scss">
	@import '@/style/variables.scss';

	.pageTitle {
		background-color: $tableBorderColor;
		padding: 10px;
	}

	.form {
		// width: 500px;
		input{
			// width: 200px;
		}
	}

	.qrcode {
		// display: inline-block;
		width: 100%;
		height: 100%;
		text-align: center;

		img {
			max-width: 100%;
			background-color: #fff; //设置白色背景色
			box-sizing: border-box;
		}
	}
</style>
